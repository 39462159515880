<template>
  <VList
    :hide-header="true"
    :rows="displayedRows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <!-- eslint-disable-next-line -->
    <template #header.competency_score="{ header }">
      <div class="flex" :class="header.headerClass">
        {{ header.text }}

        <VHint class="self-start ml-2">
          {{ $t("hints.teaching_learners_competency_score") }}
        </VHint>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #header.completion_score="{ header }">
      <div class="flex" :class="header.headerClass">
        {{ header.text }}

        <VHint class="self-start ml-2">
          {{ $t("hints.teaching_learners_completion_score") }}
        </VHint>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :src="item.displayed_avatar" :name="item.displayed_name" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="cursor-pointer" @click="onClickUpdate(item.id)">
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_name }}
        </div>

        <div class="text-gray-600 text-xs w-96 truncate mt-0.5">
          <Tippy tag="a" :content="item.displayed_roles">
            <div>
              {{ item.displayed_roles }}
            </div>
          </Tippy>
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.competency_score="{ item }">
      <div class="flex justify-center">
        <VScore :score="item?.competency_score" />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.completion_score="{ item }">
      <div class="flex justify-center">
        <VScore :score="item?.completion_score" />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useLearner from "@/composables/useLearner";
import useRead from "@/composables/useRead";
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VScore from "@/components/VScore";
import VHint from "@/components/VHint";

export default {
  components: {
    VList,
    VImage,
    VChip,
    VScore,
    VHint
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    const documentTitle = `${t("app.learners", 2)} - ${t("app.teaching")}`;
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.learners"),
        value: "name"
      },
      {
        text: t("app.competency_score"),
        value: "competency_score",
        headerClass: "justify-center"
      },
      {
        text: t("app.completion_score"),
        value: "completion_score",
        headerClass: "justify-center"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // Computed
    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        displayed_name: `${row.firstname} ${row.lastname}`,
        displayed_status: row.status ? t(`app.${row.status}`) : "",
        displayed_avatar: getAvatarURL(row?.avatar?.view_path),
        displayed_roles: row.roles.map(role => role.name).join(", ")
      }));
    });

    // Composables
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();
    const { endpoint, route, searchFields, types } = useLearner();
    const {
      pagination,
      rows,
      isLoading,
      onClickUpdate,
      onUpdateCurrentPage,
      onUpdatePerPage
    } = useRead({
      endpoint,
      route,
      relations: ["texts", "department"],
      types,
      searchFields
    });

    return {
      headers,
      documentTitle,
      displayedRows,
      // useColor
      getStatusColor,
      // useRead
      pagination,
      onClickUpdate,
      rows,
      isLoading,
      onUpdateCurrentPage,
      onUpdatePerPage
    };
  }
};
</script>
